<template>
  <base-dialog v-if="title" :title="title" @close="close">
    <template #secondary>
      <img src="../../images/tongielogo.png" alt="tongie logo" />
    </template>
  </base-dialog>
  <section id="main-ct">
    <div class="main-contact">
      <form @submit.prevent="formSubmit">
        <h2>Feliratkozás</h2>
        <div class="input-holder in1">
          <label for="name">Név</label>
          <input
            type="text"
            id="name"
            placeholder="Tongie Péter"
            v-model="name"
            :class="{ error: nameError }"
          />

          <div :class="{ vs: !nameError }">
            <span :v-if="nameError" class="text-error">{{ nameError }}</span>
            <span class="error-sign err1">
              <span class="label">!</span>
            </span>
          </div>
        </div>
        <div class="input-holder in2">
          <label for="email">E-mail cím</label>
          <input
            type="email"
            id="email"
            placeholder="peter@tongie.app"
            v-model="email"
            :class="{ error: emailError }"
          />
          <div :class="{ vs: !emailError }">
            <span class="text-error">{{ emailError }}</span>
            <span class="error-sign err2">
              <span class="label">!</span>
            </span>
          </div>
        </div>
        <div class="input-holder in3">
          <label>Felhasználási feltételek elfogadása</label>
          <input type="checkbox" name="confirm" v-model="confirmed" />
          <div :class="{ vs: !confirmError }">
            <span class="text-error-text">{{ confirmError }}</span>
          </div>
        </div>
        <div class="btn-contact">
          <base-button mode="shadow-active" type="submit"
            >Feliratkozom
          </base-button>
        </div>
        <div class="logo">
          <p>Tongie</p>
        </div>
      </form>

      <div class="imgs img1">
        <img src="../../images/teacher1.png" alt="teacher" />
      </div>
      <div class="imgs img2">
        <img src="../../images/Teacher3.png" alt="another teacher" />
      </div>
      <div class="imgs img3">
        <img src="../../images/Teacher2.png" alt="happy teacher" />
      </div>
      <div class="imgs img4">
        <img src="../../images/teacher4.png" alt="lady teacher" />
      </div>
    </div>
    <div class="capcha">
      <vue-recaptcha
        :sitekey="siteKey"
        theme="light"
        :loadRecaptchaScript="true"
        @verify="handleSuccess"
        @error="handleError"
      >
      </vue-recaptcha>
    </div>
    <the-footer-contact />
  </section>
  <section id="ext"></section>
  <router-view></router-view>
</template>
<script>
import BaseButton from '../base/BaseHeaderButton.vue';
import TheFooterContact from '../ui/TheFooterContact.vue';
import BaseDialog from '../base/BaseDialog.vue';
import '../../css/modules/ct.css';
import validate from 'validate.js';
import { VueRecaptcha } from 'vue-recaptcha';
export default {
  components: {
    BaseButton,
    TheFooterContact,
    BaseDialog,
    VueRecaptcha,
  },
  data() {
    return {
      name: '',
      email: '',
      nameError: null,
      emailError: null,
      confirmError: null,
      title: '',
      confirmed: false,
      siteKey: '',
      validated: false,
    };
  },
  methods: {
    handleSuccess(response) {
      if (response != null) {
        this.validated = true;
      }
    },
    handleError() {
      this.validated = false;
    },
    getSiteKey() {
      this.siteKey = process.env.VUE_APP_RECAPCA_SITE_KEY;
    },
    resetEmailFields() {
      this.email = '';
      this.name = '';
    },

    async formSubmit() {
      if (!this.validated) return;
      const baseUrl =
        process.env.NODE_ENV !== 'development'
          ? 'https://api.tongie.app/landing/'
          : '/';
      this.resetForm();
      const errValue = await validate.single(this.name, {
        presence: true,
        format: {
          pattern: '[^<>{}()]+',
        },
      });
      const errEmailValue = await validate.single(this.email, {
        presence: true,
        format: {
          pattern: '[^<>{}()]+',
        },
      });
      if (errValue) {
        this.nameError = 'Helytelen név használat';
      }
      if (errEmailValue) {
        this.emailError = 'Helytelen email';
      }
      if (!this.confirmed) {
        this.confirmError = 'Felhasználási feltételek elfogadása kötelezö!';
      }
      if (this.emailError || this.nameError || !this.confirmed) {
        return;
      }
      try {
        const response = await fetch(`${baseUrl}subscribe`, {
          method: 'POST',
          body: JSON.stringify({
            email: this.email,
            mssys_firstname: this.name,
            mssys_referer_page: 'HU',
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status < 400) {
          this.resetForm();
          this.resetEmailFields();
          return this.addTitle('Köszönjük regisztrációd');
        } else {
          const mess = await response.json();
          return this.addTitle(mess['message']);
        }
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.title = '';
    },
    addTitle(tit) {
      this.title = tit;
    },
    resetForm() {
      this.emailError = null;
      this.confirmError = null;
      this.nameError = null;
    },
    navi() {
      this.$router.push('/home');
    },
  },
  created() {
    this.siteKey = process.env.VUE_APP_RECAPCA_SITE_KEY;
  },
};
</script>
<style scoped>
.capcha {
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translate(-50%, -50%);
}
.vs {
  visibility: hidden;
}
.vis {
  visibility: visible;
}
section {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
  position: relative;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
}

section .main-contact {
  width: 100%;
  height: 56vh;
  position: absolute;
  top: 10%;
}

section .main-contact h2 {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #353535;
  font-family: 'Poetsen', sans-serif;
  font-size: 30px;
}
section .main-contact form {
  width: 60%;
  position: absolute;
  left: 50%;
  top: 20%;
  height: 100%;
  transform: translate(-50%, -20%);
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 10px #00000026;
  border: 1px dashed #a7a7a7;
  border-radius: 30px;
}

section .input-holder .text-error {
  position: fixed;
  bottom: -30%;
  left: 2%;
  z-index: 5;
  color: #c62222;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

section .input-holder input[type='text'],
section .input-holder input[type='email'] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55px;
  padding: 2%;
  outline: none;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 30px #0000001a;
  border: 1px solid #4e107d;
  border-radius: 20px;
}
section .input-holder .text-error-text {
  color: #c62222;
  transform: translate(17px, 27px);
  position: absolute;
}
section .input-holder .error {
  background: #ec9a9a79 !important;
  border: 1px solid #c62222 !important;
}
section .in1,
section .e1 {
  top: 25%;
}
section .in1 label,
section .in2 label {
  color: #434343;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  line-height: 1.2rem;
}
section .in1 input::placeholder,
section .in2 input::placeholder {
  font-family: 'Roboto', sans-serif;
  color: #353535;
  font-size: 18px;
}

section .in2,
section .e2 {
  top: 35%;
}
section .in3 {
  top: 41%;
}
section .in3 input {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
}
section .in3 label {
  position: absolute;
  left: 40px;
  top: 5%;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}
section .btn-contact {
  position: absolute;
  bottom: 11%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, 0);
}
section .logo {
  position: absolute;
  bottom: -3%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
}
section .logo p {
  text-align: center;
  color: #e0e0e0;
  font-weight: 600;
  font-size: 27px;
}
section .input-holder .error-sign {
  background: transparent linear-gradient(180deg, #c62222 0%, #a00101 100%);
  width: 18px;
  height: 18px;
  border-radius: 10px;
  z-index: 5;
  position: absolute;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
section .input-holder .err1 {
  top: 52%;
}
section .input-holder .err2 {
  top: 55%;
}

section .input-holder .error-sign .label {
  font-size: 14px;
  text-align: justify;
  color: #e0e0e0;
}
section .imgs {
  position: absolute;
  width: 200px;
  height: 300px;
}
section .imgs img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  /* position: absolute; */
}
section .img1 {
  left: 10%;
  top: 2%;
}
section .img2 {
  left: 5%;
  top: 47%;
}
section .img3 {
  right: 5%;
  top: 0;
}
section .img4 {
  right: 7%;
  top: 40%;
}
section .img2 img {
  width: 250px;
  height: 250px;
}
section .input-holder {
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80px;
}
section .header-top {
  height: 7vh;
  position: absolute;
  width: 100vw;
  top: 0;
  border-radius: 0 0 30px 30px;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
}
section .header-top .lo {
  position: absolute;
  /* left: 5%; */
  color: #ffc507;
  top: 15%;
  margin-left: 5%;
  font-family: 'Poetsen', sans-serif;
  font-size: 30px;
  cursor: pointer;
  height: auto;

  /* transform: translate(-50%, -50%); */
}

#ext {
  width: 0;
  height: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}
</style>
