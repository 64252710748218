<template>
  <section id="header">
    <div class="first select">
      <div class="lg">
        <div class="logo">
          <h1>Tongie</h1>
        </div>
      </div>
      <div class="svg-container">
        <img src="../../svg/BigLogo.png" alt="" />
      </div>
    </div>
    <div class="second select">
      <div class="inner">
        <h1>A szótanulás még sosem volt ilyen könnyű</h1>
        <h4>
          Most Te is segíthetsz, hogy a nyelvtanulás szenvedés helyett
          szórakozás legyen
        </h4>
      </div>
      <div class="button-container">
        <!-- <base-btn mode="active" link="/contact"> Feliratkozom</base-btn>
        <base-btn mode="active" link="/contact"> Tovább</base-btn> -->
        <base-header-button mode="active" @scroll="scrollD">
          Tovább</base-header-button
        >
        <base-header-button mode="passive" @scroll="scrollD">
          Tovább</base-header-button
        >
      </div>
    </div>
  </section>
</template>
<script>
// import BaseBtn from '../base/BaseButton.vue';
import BaseHeaderButton from '../base/BaseHeaderButton.vue';
import '../../css/modules/header.css';
export default {
  components: {
    // BaseBtn,
    BaseHeaderButton,
  },
  methods: {
    navi() {
      this.$router.push('/contact');
    },
    scrollD() {
      const el = document.getElementById('main-ct');
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
section {
  display: grid;
  grid-template-columns: 35% 65%;
  height: 100vh;
  width: 100vw;
}
section .second {
  position: relative;
  width: 100%;
  height: 100%;
}
section .second .inner {
  height: 90%;
  width: 100%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
  transform: matrix(1, -0.09, 0.09, 1, 100, -29);
  border-radius: 248px;
  position: relative;
  text-align: justify;
}
section .second .button-container {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10%;
  width: 60%;
  left: 25%;
  top: 50%;
}
section .second .inner h1 {
  position: absolute;
  right: 25%;
  top: 30%;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 40px;
  font-family: 'Poetsen', sans-serif;
  transform: matrix(1, 0.09, 0, 1.2, 0, 0);
  text-align: center;
}
section .second .inner h4 {
  position: absolute;
  right: 25%;
  top: 40%;

  color: #ffffff;
  opacity: 1;
  transform: matrix(1, 0.09, 0, 1, 0, 0);
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}
section .first {
  position: relative;
  width: 100%;
  height: 100%;
}
section .first .lg {
  height: 100px;
}
section .first .logo {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
  transform: matrix(1, 0.09, -0.09, 1.2, -1.9, 0);
  border-radius: 0 0 90px 0;
  opacity: 1;
  top: 0;
  left: 0;
  width: 200px;
  height: auto;
  position: absolute;
  text-align: center;
}
section .first .logo h1 {
  font-size: 30px;
  color: #ffc507;
  transform: matrix(1, -0.1, -0.09, 1.2, -1.9, 0);
  font-family: 'Poetsen';
}
section .svg-container {
  height: 100%;
  width: 100%;
  position: relative;
}
section .svg-container img {
  max-height: 600px;
  max-width: 500px;
  position: absolute;
  left: 85%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
