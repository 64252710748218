<template>
  <section id="theapp">
    <div class="text">
      <h2>
        <!-- Az alkalmazáson túl <span class="marker">közösséget</span> is teremtünk -->
        Gondtalanul illeszkedik nyelvtanulásodba
      </h2>
      <h4>
        <!-- Ha gondoltál már rá, milyen jó lenne hozzád hasonló nyitott és motivált
        kollégákkal megosztani tapasztalataid és tudásod, és közösen fejlődni,
        akkor jó helyen jársz. A Tongie megteremti a
        <span class="mark">jófejség </span>új mértékegységét és kialakítja
        számotokra a felületet mindehhez. -->
        Alkalmazásunk használata nem csupán egyszerű, de felhasználási
        lehetősége rendkívül sokszínű. Épp ezért tökéletesen be tudod illeszteni
        napirendedbe és tanulmányaidba, így nem egy újabb felesleges appot,
        hanem egy új távlatot adunk a kezedbe.
      </h4>
    </div>
    <div class="top">
      <div class="slider-holder">
        <splide :options="options">
          <splide-slide>
            <img
              src="../../images/online-meeting-with-marking-team-2021-08-30-01-21-42-utc.png"
            />
          </splide-slide>
          <!-- <splide-slide>
            <img src="../../images/Sadteacher.png" />
          </splide-slide> -->
        </splide>
      </div>
    </div>
    <div class="bottom">
      <div class="btn-container">
        <base-btn mode="shadow-active" @scroll="scrollD">
          Csatlakozom
        </base-btn>
      </div>
    </div>
    <span class="bubble b1"> </span><span class="bubble b2"></span>
  </section>
</template>
<script>
import '../../css/modules/theapp.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import BaseBtn from '../base/BaseHeaderButton.vue';
export default {
  components: {
    Splide,
    SplideSlide,
    BaseBtn,
  },
  methods: {
    scrollD() {
      const el = document.getElementById('contact');
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
  data() {
    return {
      options: {
        easing: 'ease',
        autoPlay: true,
        interval: 500,
        rewind: true,
        gap: '10rem',
        pagination: true,
        cover: true,
        fixedWidth: 650,
        fixedHeight: 650,
        focus: 'center',
        updateOnMove: true,
        // type: 'loop',
        perMove: 1,
        breakpoints: {
          1650: {
            gap: 0,
            fixedWidth: 500,
            fixedHeight: 500,
          },
          1350: {
            fixedWidth: 550,
            fixedHeight: 550,
            gap: 0,
          },
          1025: {
            fixedWidth: 550,
            fixedHeight: 550,
            gap: 0,
          },
          850: {
            fixedWidth: 450,
            fixedHeight: 450,
          },
          650: {
            fixedWidth: 330,
            fixedHeight: 330,
          },
          550: {
            fixedWidth: 290,
            fixedHeight: 280,
          },
          450: {
            fixedWidth: 330,
            fixedHeight: 280,
          },
          400: {
            fixedWidth: 280,
            fixedHeight: 280,
          },
        },
        classes: {
          arrows: 'splide__arrows my_arrow',
        },
      },
    };
  },
};
</script>
<style scoped>
section {
  height: 100vh;
  width: 100vw;
  position: relative;
}

section .text {
  position: absolute;
  top: 12%;
  z-index: 1;
  overflow: hidden;
  width: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section .text h2,
h4 {
  color: #fff;
}
section .text h2 {
  text-align: center;
  font-size: 43px;
  font-family: 'Poetsen', sans-serif;
}
section .text h4 {
  text-align: center;
  font-size: 26px;
  font-weight: 400;
}
section .text .mark,
section .text .marker {
  color: #fbcf30;
}
section .top {
  width: 100vw;
  height: 70%;
  z-index: -1;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #521084;
  transform: matrix(1, -0.02, 0.02, 1, 0, 0);
}

section .top .slider-holder {
  position: relative;
  width: 65%;
  height: 50%;
  margin: 10% auto;
  padding: 0;
}
section .top .slider-holder .splide_slide img {
  object-fit: contain;
  width: 750px !important;
  height: 100%;
  border-radius: 20px !important;
  display: none;
}
section .bottom {
  height: auto;
  margin: auto;
}
section .b1 {
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  background: #551188 0% 0% no-repeat padding-box;
  left: -2%;
  width: 100px;
  height: 100px;
  border-radius: 25px;
  bottom: 5%;
}
section .b2 {
  transform: matrix(-1, -0.09, 0.09, -1, 0, 0);
  background: #12001f 0% 0% no-repeat padding-box;
  border-radius: 20px;
  left: 2%;
  width: 50px;
  height: 50px;
  bottom: 5%;
}
section .bottom .btn-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
}
</style>
