<template>
  <section>
    <vue-pdf-embed
      :source="source"
      :disable-annotation-layer="true"
      :width="windWith"
    />
    <div class="holder">
      <div class="btn-holder">
        <base-button mode="outline" link="/home"> Vissza </base-button>
      </div>
    </div>
  </section>
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed';
import BaseButton from '../components/base/BaseButton.vue';
export default {
  components: {
    VuePdfEmbed,
    BaseButton,
  },
  data() {
    return {
      source: 'a.pdf',
      windWith: window.innerWidth * 0.8,
    };
  },
  methods: {
    setWindowWidth() {
      this.windWith = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.setWindowWidth);
  },
};
</script>
<style>
section {
  position: relative;
  display: flex;
  justify-content: center;
}
.holder {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}
.btn-holder {
  width: calc(100vw / 6);
}
</style>
