<template>
  <section id="phones">
    <div class="top"></div>
    <div class="bottom">
      <h1>Megérkezett a szókártyák új generációja: <span>a Tongie</span></h1>
      <h4>
        Tanárokkal és tanulókkal alakítottuk ki vadonatúj szótanuló
        alkalmazásunkat, mely egy új impulzust és azonnal használható segítséget
        nyújthat Neked az eredményesebb felkészülésben és aktív szókincsed
        bővítésében
      </h4>
      <div class="cover">
        <div class="left">
          <img src="../../images/Likestudent.png" alt="like student" />
        </div>
        <div class="center">
          <img src="../../images/phones.png" alt="phones cover" />
        </div>
        <div class="right">
          <img src="../../images/Loveteacher.png" alt="Love teacher" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import '../../css/modules/phones.css';
export default {};
</script>

<style scoped>
section {
  position: relative;
  height: 100vh;
  width: 100vw;
}
section .top {
  width: 100%;

  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
  height: 65%;
}
section .bottom h1 {
  text-align: center;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-family: 'Poetsen', sans-serif;
  letter-spacing: 1px;
  font-size: 35px;
}
section .bottom h1 span {
  color: #fec524;
}
section .bottom h4 {
  word-wrap: normal;
  color: #ffffff;
  position: absolute;
  top: 14%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 1px;
}
section .cover {
  display: grid;
  width: 100%;
  grid-template-columns: 20% 60% 20%;
  position: absolute;
  top: 70%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
section .cover .center,
section .cover .left,
section .cover .right {
  position: relative;
}
section .cover .center img {
  position: absolute;
  left: 50%;
  top: 0;
  width: 130%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
section .cover .left img,
section .cover .right img {
  position: absolute;
  object-fit: cover;
  width: 100%;
}
section .cover .right img {
  bottom: -80px;
  right: 10%;
}
section .cover .left img {
  bottom: 80px;
  left: 28%;
}
</style>
