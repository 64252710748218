<template>
  <section id="footer-m">
    <div class="footer-main">
      <div class="img-cont">
        <img src="../../images/tongielogo.png" alt="tongie logo" />
      </div>
      <footer>
        <div class="separator sep1">2021 Tongie. Minden jog fenntartva</div>
        <div class="separator sep2">Tongie</div>
        <div class="separator sep3">
          <router-link router-link to="/aszf">
            Általános szerzödési feltételek </router-link
          >|
          <router-link to="/adatkezeles">Adatkezelési tályékoztató</router-link>
        </div>
      </footer>
    </div>
  </section>
  <cookie-comply></cookie-comply>
</template>
<script>
import '../../css/modules/footer-contact.css';
import CookieComply from '../../components/cookies/CookieComply.vue';

// example or use it
// ('./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom');

export default {
  setup() {},
  methods: {
    onSavePreferences(preferences) {
      console.log(preferences); // ['performance', 'ga', 'newRelic']
    },
  },
  // methods: {
  //   navi(link) {
  //     this.$router.push(link);
  //   },
  // },
  components: {
    CookieComply,
  },
  data() {
    return {
      preferences: [
        {
          title: 'Performance',
          description:
            'Bla bla serviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer serviços que podemos oferecer.',
          items: [{ label: 'Active', value: 'performance', isRequired: true }],
        },
        {
          title: 'Analytics',
          description:
            'Bla bla serviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer serviços que podemos oferecer.',
          items: [
            { label: 'GoogleAnalytics', value: 'ga', isRequired: true },
            { label: 'Sentry', value: 'sentry', isEnable: true },
            { label: 'Mapbox', value: 'mapbox' },
            { label: 'New Relic', value: 'newRelic', isEnable: true },
            { label: 'Dog Food', value: 'dogfood' },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped>
#footer-m {
  width: 100%;
  overflow: hidden;
  height: 27%;
  position: absolute;
  bottom: 0;
}
#footer-m .footer-main {
  width: 100%;
  height: 100%;
  margin: auto;
}
footer {
  position: absolute;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  text-align: center;
  width: 100vw;
  height: 25%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;

  border-radius: 40px 40px 0 0;
  right: 0;
}
footer .separator {
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .sep1,
footer .sep3 {
  color: #ffffff;
  text-align: left;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
}
footer .sep2 {
  color: #fec524;
  font-size: 32px;
  font-family: 'Poetsen', sans-serif;
  letter-spacing: 1px;
}
footer .sep3 a {
  color: #fff;
  text-decoration: none;
  padding: 5px;
}
section .footer-main .img-cont {
  width: 100%;
  position: absolute;
  bottom: 6%;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -50%);
}
section .footer-main .img-cont img {
  position: absolute;
  left: 50%;
  bottom: -2%;
  transform: translate(-50%, -15%);
  width: 150px;
  height: 150px;
  object-fit: contain;
}
</style>
