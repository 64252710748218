<template>
  <the-header></the-header>
  <the-helper></the-helper>
  <the-phones></the-phones>
  <the-cards></the-cards>
  <the-bubbles></the-bubbles>
  <the-app></the-app>
  <the-learning></the-learning>
  <the-possibility></the-possibility>
  <the-con></the-con>
  <!-- <the-extension></the-extension> -->

  <router-view> </router-view>
</template>

<script>
import TheHeader from './TheHeader.vue';
import TheHelper from './TheHelper.vue';
import ThePhones from './ThePhones.vue';
import TheCards from './TheCards.vue';
import TheBubbles from './TheBubbles.vue';
import TheApp from './TheApp.vue';
import TheLearning from './TheLearning.vue';
import ThePossibility from './ThePossibility.vue';
// import TheExtension from './TheExtension.vue';
import TheCon from './TheCon.vue';
export default {
  components: {
    TheHeader,
    TheHelper,
    ThePhones,
    TheCards,
    TheBubbles,
    TheApp,
    TheLearning,
    ThePossibility,
    TheCon,
    // TheExtension,
  },

  computed: {
    checkParams() {
      if (this.$route.path && this.$route.path === '/home') {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      isModalVisible: true,
    };
  },
};
</script>
<style scoped>
.layer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
