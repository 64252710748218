A véletlenül közzétett, az Ügyfél Elkertv. 13. §-ban meghatározott jogát sértő
információ esetén Szolgáltató az Elkertv. szerinti megkeresés esetén eleget tesz
az Elkertv-ben foglalt értesítési és eltávolítási kötelezettségeinek.
Szolgáltató úgyszintén törvényes határidőben eleget tesz bármely hatósági vagy
bírósági megkeresésnek.
<template>
  <section>
    <h1>ÁLTALÁNOS SZERZŐDÉSI FELTÉTELEK</h1>
    <div class="pholder">
      <p>
        A jelen Általános Szerződési Feltételek (a továbbiakban: ÁSZF) Babics
        Bence (továbbiakban: Szolgáltató), és a Szolgáltató által üzemeltetett
        tongie.app.hu weboldalon (továbbiakban: „Weboldal”), illetve a „tongie”
        elnevezésű, az Apple Store-ban és a Google Play Áruházban elérhető
        applikáción (a továbbiakban: Applikáció) keresztül nyújtott, 2001. évi
        CVIII. törvény (továbbiakban: Elkertv.) 2. § a) pontja szerinti
        elektronikus kereskedelmi szolgáltatásokat igénybe vevő Ügyfél (a
        továbbiakban: Ügyfél, ideértve mind az applikáción keresztül tartalmat
        létrehozó, mind az applikációt egyéb módon használó személyeket) jogait
        és kötelezettségeit tartalmazzák. A weboldal, illetve az applikáció
        használatával összefüggő gyakorlati részletkérdések a szolgáltató saját
        honlapján kerülnek ismertetésre.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        Jelen ÁSZF minden szerződésre és szolgáltatásra vonatkozik, amelyet
        Szolgáltató és Ügyfél (továbbiakban együtt: Felek) a fent megjelölt
        weboldallal és applikációval kapcsolatban kötnek.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        A szolgáltatásokat a Szolgáltató valamennyi Ügyfele jogosult használni,
        amennyiben magára nézve kötelezőnek ismeri el a jelen ÁSZF-ben
        foglaltakat.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        Elkertv. 2. § l) pont ld) alpontja alapján a Szolgáltató tevékenysége
        alapján közvetítő szolgáltatónak minősül. A Szolgáltató nem köteles
        ellenőrizni az általa csak továbbított, tárolt, hozzáférhetővé tett
        információt, továbbá nem köteles olyan tényeket vagy körülményeket
        keresni, amelyek jogellenes tevékenység folytatására utalnak. A
        Szolgáltató ennek megfelelően nem felelős a Felhasználók által végzett
        esetleges marketing tevékenységekért, adatkezelésékért.
        Alkalmazásszolgáltatónak minősül az a természetes, illetve jogi személy
        vagy jogi személyiséggel nem rendelkező más szervezet, aki, vagy amely
        elektronikus hírközlő hálózat felhasználásával valamilyen szoftverhez
        vagy hardverhez való hozzáférést, szoftveres alkalmazást, valamint
        kapcsolódó szolgáltatásokat biztosít specifikus szoftveren vagy webes
        felületen több felhasználó számára, időben korlátozott vagy korlátlan
        módon, havi- vagy használat alapú ellenszolgáltatás fejében vagy
        ingyenes formában.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        A Szolgáltató applikációján keresztül a Felhasználók idegen nyelvű
        szókártyákat hozhatnak létre. A Szolgáltató nem felelős az applikáció
        Felhasználói által a szókártyákon létrehozott tartalmakért, különösen e
        tartalmak helyességéért és pontosságáért.
      </p>
    </div>
    <br />
    <br />
    <div class="pholder">
      <h3>1. SZOLGÁLTATÓ ADATAI</h3>
      <p>Név: Babics Bence</p>
      <p>Lakcím: 9090 Pannonhalma, Cseidervölgy 13.</p>
      <p>Adószám: […]</p>
      <p>E-mail cím: […]</p>
      <p>Telefonszám: […]</p>
    </div>
    <br />
    <div class="pholder">
      <h3>2. ÁLTALÁNOS RENDELKEZÉSEK</h3>
      <p>
        <span class="str">2.1.</span>A Szolgáltató által nyújtott
        mobilapplikáció használatához annak letöltése szükséges.
      </p>
      <p>
        <span class="str">2.2.</span>
        Ügyfél a mobilapplikáció, illetve a weboldal használatára vonatkozó
        igényét a letöltött Applikáció, illetve a weboldal megnyitását követően
        megjelenő adatlap valamennyi, regisztrációhoz szükséges kitöltendő
        mezőjének kitöltését követően tudja megküldeni a Szolgáltató részére. A
        Felek között ily módon – magyar nyelven - létrejött szerződés írásba
        foglalt szerződésnek minősül. Amennyiben a Szolgáltató jelen ÁSZF-t a
        magyar mellett más nyelven is közzéteszi, abban az esetben a magyar
        nyelvű ÁSZF rendelkezései irányadóak.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">2.3</span>
        Az Ügyfél a regisztráció megküldésével kijelenti, hogy jelen ÁSZF,
        valamint az Applikációhoz és a weboldalhoz tartozó Adatkezelési
        tájékoztató valamennyi rendelkezését és feltételeit megismerte és
        elfogadja.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">2.4.</span>
        A Szolgáltatót az Ügyfél által tévesen és/vagy pontatlanul megadott
        adatokra visszavezethető következményekért (hibáért vagy károkért,
        például aktiváló kód el nem érése) semminemű felelősség nem terheli. A
        Szolgáltatót nem terheli felelősség az abból adódó károkért, ha az
        Ügyfél e-mail címét vagy a regisztrációhoz használt jelszavát elfelejti,
        vagy az illetéktelenek számára bármely, nem a Szolgáltatónak felróható
        okból hozzáférhetővé válik. A Szolgáltató minden regisztrációt egy
        önálló Ügyfélként kezel. Szolgáltatót a részére megadott adatok Ügyfél
        által történő megváltoztatásából eredő kárért, hibáért semminemű
        felelősség nem terheli.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">2.5.</span> A felhasználói adatok titokban tartásáért
        kizárólag az Ügyfél a felelős. Amennyiben Ügyfél tudomást szerez arról,
        hogy a regisztráció során megadott e-mail címéhez jogosulatlan harmadik
        személy hozzájuthatott, köteles haladéktalanul értesíteni a
        Szolgáltatót.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">2.6.</span>
        Az Ügyfél vállalja, hogy a regisztráció során megadott személyes
        adatokat szükség szerint – az Ügyfél külön e-mailes értesítése alapján,
        vagy a bejelentkezést követően saját felületén – frissíti annak
        érdekében, hogy azok időszerűek, teljesek és a valóságnak megfelelőek
        legyenek. Az adatok Szolgáltató felé nem közölt megváltoztatásából eredő
        bármilyen többletköltség az Ügyfelet terheli.
      </p>
    </div>
    <br />
    <br />
    <div class="pholder">
      <h3>3. SZOLGÁLTATÓ KÖTELEZETTSÉGEI</h3>
      <p>
        <span class="str">3.1.</span>

        Szolgáltató kötelezettsége jelen ÁSZF-nek megfelelően az Applikációt és
        a weboldalt az Ügyfél rendelkezésére bocsátani.
      </p>
    </div>
    <div class="pholder">
      <p>
        <span class="str">3.2.</span>
        Szolgáltató köteles az Applikációt és weboldalt azok nyilvános
        leírásában meghatározottak szerint szolgáltatni.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">3.3.</span>
        Szolgáltató köteles biztosítani, hogy az Ügyfél értesítést kapjon az
        applikáció olyan frissítéseiről - ideértve a biztonsági frissítéseket is
        -, amelyek az applikáció használatának, funkcionalitásának,
        kompatibilitásának fenntartásához szükségesek, valamint a Szolgáltatónak
        biztosítania kell, hogy az Ügyfél meg is kapja e frissítéseket.
      </p>
    </div>
    <br />
    <div class="pholder">
      <h3>4. ADATBEVITELI HIBÁK JAVÍTÁSA</h3>
      <p>
        Az Ügyfélnek bármikor lehetősége van bármely adatbeviteli hiba
        javítására e-mailben.
      </p>
    </div>
    <br />
    <div class="pholder">
      <h3>5. FELELŐSSÉG, ALKALMAZANDÓ JOG</h3>
      <p>
        <span class="str">5.1</span>
        A véletlenül közzétett, az Ügyfél Elkertv. 13. §-ban meghatározott jogát
        sértő információ esetén Szolgáltató az Elkertv. szerinti megkeresés
        esetén eleget tesz az Elkertv-ben foglalt értesítési és eltávolítási
        kötelezettségeinek. Szolgáltató úgyszintén törvényes határidőben eleget
        tesz bármely hatósági vagy bírósági megkeresésnek.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">5.2.</span>
        A Szolgáltató kizár minden felelősséget az Ügyfél által megvalósított
        esetleges jogsértésekért.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">5.3.</span>
        Jelen ÁSZF-re a magyar jog alkalmazandó.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">5.4.</span>
        Amennyiben az Ügyfél az Applikációban, vagy a weboldalon kifogásolható
        tartalmat észlel, köteles azt haladéktalanul jelezni a Szolgáltatónak.
        Amennyiben a Szolgáltató jóhiszemű eljárása során a jelzést
        megalapozottnak találja, jogosult az információ haladéktalan törlésére
        vagy annak módosítására.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">5.5.</span>
        Akkor nem felel a továbbított információért a szolgáltató, ha (1.) nem a
        szolgáltató kezdeményezi az információ továbbítását, emellett (2.) nem a
        szolgáltató választja meg a továbbítás címzettjét, és (3.) a továbbított
        információt nem a szolgáltató választja ki, illetve azt nem változtatja
        meg.
      </p>
    </div>
    <div class="pholder">
      <p>
        <span class="str">5.6.</span>
        A Szolgáltató kijelenti, hogy az általa a jelen Honlap és applikáció
        működtetése során végzett adatkezelési tevékenység vonatkozásában önálló
        adatkezelőnek minősül, így az általános adatvédelmi rendeletben, illetve
        a vonatkozó ágazati jogszabályokban szereplő adatkezelői kötelezettségek
        külön vonatkoznak rá, az adatkezelés céljait és eszközeit nem közösen
        határozza meg azon egészségügyi szolgáltatókkal, akik a weboldal
        használata során páciensek adatait kezeli. A Szolgáltató szavatolja,
        hogy a rendszerben tárolt személyes adatokat jogszerűen szerzi meg és
        azokat megfelelő jogalappal kezeli.
      </p>
    </div>
    <div class="pholder">
      <h3>6. SZELLEMI TULAJDON</h3>
      <p>
        <span class="str">6.1.</span>
        Az Applikáció és a weboldal, annak képi és szöveges valamint szerkezeti
        kialakítása egyéni eredeti jelleget hordoz, így szerzői jogi védelem
        alatt áll. A Szolgáltató a szerzői jogi jogosultja az Applikációban,
        illetve a weboldalon megjelenített, annak funkcióihoz tartozó valamennyi
        tartalomnak: bármely szerzői műnek, illetve más szellemi alkotásnak. A
        Szolgáltatónak kizárólagos joga van az Applikáció, illetve a weboldal
        egészének vagy valamely azonosítható részének anyagi formában és nem
        anyagi formában történő bármilyen felhasználására és minden egyes
        felhasználás engedélyezésére. A Szolgáltatót megilleti az Applikációban,
        illetve a weboldalon szereplő jellegzetes és eredeti alak kereskedelmi
        hasznosításának és az ilyen hasznosítás engedélyezésének kizárólagos
        joga is.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">6.2</span>
        Az Applikáció, illetve a weboldal tartalmának másolása, egészének vagy
        részeinek fizikai vagy más adathordozóra mentése vagy nyomtatása
        kizárólag a Szolgáltató előzetes írásbeli hozzájárulása esetén
        engedélyezett. A Szolgáltató engedélye szükséges az Applikáció, illetve
        a weboldal sajátos címének felhasználásához is.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p><span class="str"></span></p>
    </div>
    <div class="pholder">
      <p>
        <span class="str">6.3.</span>
        A jelen ÁSZF-ben kifejezetten meghatározott jogokon túlmenően a
        regisztráció, az Applikáció, illetve a weboldal használata, illetve az
        ÁSZF egyetlen rendelkezése sem biztosít jogot az Ügyfélnek a Honlap
        felületén található kereskedelmi nevek vagy védjegyek bármely
        használatára, hasznosítására. Jogosulatlan a felhasználás különösen
        akkor, ha arra törvény vagy az arra jogosult szerződéssel engedélyt nem
        ad, vagy ha a felhasználó jogosultságának határait túllépve használja
        fel a művet.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p><span class="str"></span></p>
    </div>
    <div class="pholder">
      <p>
        <span class="str">6.4.</span>
        Szolgáltató személyhez fűződő jogát sérti az Applikáció, illetve a
        weboldal mindenfajta eltorzítása, vagy más olyan megváltoztatása vagy az
        Applikációval, illetve a weboldallal kapcsolatos más olyan visszaélés,
        amely hírnevére sérelmes.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">6.5</span>
        A Honlappal és az Applikációval kapcsolatos szerzői jogi kérdésekre
        egyebekben a szerzői jogról szóló 1999. évi LXXVI. törvény (Szjt.)
        rendelkezései irányadóak.
      </p>
    </div>
    <br />
    <div class="pholder">
      <h3>7. EGYÉB RENDELKEZÉSEK</h3>
      <p>
        <span class="str">7.1.</span>
        A Szolgáltató bármikor jogosult jelen ÁSZF feltételeit a jövőre
        kiterjedő hatállyal egyoldalúan módosítani. A Szolgáltató a
        módosításokról az Ügyfeleket az Applikáció, illetve a weboldal felületén
        keresztül tájékoztatja. A módosítást követően az Applikáció, illetve a
        weboldal használatának feltétele, hogy az Ügyfél az Applikáción, illetve
        a weboldalon keresztül és az ott biztosított módon azokat kifejezetten
        elfogadja. Újabb szerződéskötés esetén a korábban már szerződést kötő
        Ügyfél a fenti tájékoztatás esetleges elmaradása esetén sem hivatkozhat
        a korábban hatályos ÁSZF tartalmára, mint a Felek között kialakult és
        alkalmazott szerződési gyakorlatra.
      </p>
    </div>
    <br />
    <div class="pholder">
      <p>
        <span class="str">7.2.</span>
        A jelen ÁSZF-ben nem szabályozott kérdésekben a magyar jog, így
        különösen a Ptk., az Elkertv. és az elektronikus hírközlésről szóló
        2003. évi C. törvény (a továbbiakban: Eht.) rendelkezései az irányadók.
        A Honlap és az applikáció használatának ténye feltételezi az Ügyfél
        részéről az internet technikai és műszaki korlátainak, veszélyeinek
        ismeretét és az elektronikus kereskedelmi szolgáltatások
        igénybevételével együtt járó hibalehetőségek elfogadását.
      </p>
    </div>
    <br />
    <div class="prov">
      <p>Hatálybalépés dátuma: 2022. ………</p>
      <p>
        <strong> Babics Bence Szolgáltató </strong>
      </p>
    </div>
    <div class="holder">
      <div class="btn-holder">
        <base-button link="/" mode="outline"> Vissza </base-button>
      </div>
    </div>
  </section>
</template>
<style scoped>
section {
  width: 95vw;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.pholder p {
  letter-spacing: 0.5px;
  text-align: left;
}
.pholder .str {
  font-weight: bold;
  padding-right: 3px;
}
.prov {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.holder {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn-holder {
  width: 300px;
}
</style>
<script>
import BaseButton from '../components/base/BaseButton.vue';
export default {
  components: {
    BaseButton,
  },
};
</script>
