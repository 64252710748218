import { createApp } from 'vue';
import './css/index.css';
import App from './App.vue';
import BaseButton from './components/base/BaseButton.vue';
import BaseDialog from './components/base/BaseDialog';
import { createRouter, createWebHistory } from 'vue-router';
import TheLayer from './components/ui/TheLayer.vue';
import TheContact from './components/ui/TheContact.vue';
import BaseActionButton from './components/base/BaseActionButton.vue';
import VueCookieComply from 'vue-cookie-comply';
// import 'vue-cookie-comply/dist/style.css';
import aszf from './pages/aszf.vue';
import akta from './pages/akta.vue';
const app = createApp(App);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-action-button', BaseActionButton);
app.component(VueCookieComply);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: TheLayer,
      redirect: '/home',
    },
    {
      path: '/contact',
      component: TheContact,
      name: 'TheContact',
    },
    {
      path: '/aszf',
      component: aszf,
      name: 'aszf',
    },
    {
      path: '/adatkezeles',
      component: akta,
      name: 'akta',
    },
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});
app.use(router);

app.mount('#app');
