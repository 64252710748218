<template>
  <aside v-if="showCookieComply" class="cookie-comply">
    <div class="cookie-comply__header">
      <slot name="header">
        <h3 class="cookie-comply__header-title">{{ headerTitle }}</h3>
        <p class="cookie-comply__header-description">{{ headerDescription }}</p>
      </slot>
    </div>

    <div class="cookie-comply__actions">
      <cookie-comply-button @handleClick="openPreferences">
        {{ preferencesLabel }}
      </cookie-comply-button>
      <cookie-comply-button
        class-name="cookie-comply__button-accept"
        @handleClick="handleAcceptAll"
      >
        {{ acceptAllLabel }}
      </cookie-comply-button>
    </div>

    <!-- <Teleport :to="target"> -->
    <cookie-comply-modal
      v-if="isModalOpen"
      :preferences="preferences"
      @cookie-comply-save="onSave"
      @cookie-comply-close="isModalOpen = false"
    >
      <template #modal-header>
        <slot name="modal-header"></slot>
      </template>

      <template #modal-body="{ preference, index }">
        <slot name="modal-body" :preference="preference" :index="index"></slot>
      </template>

      <template #modal-footer>
        <slot name="modal-footer"></slot>
      </template>
    </cookie-comply-modal>
    <!-- </Teleport> -->
  </aside>
</template>

<script>
import CookieComplyModal from './CookieComplyModal.vue';
import CookieComplyButton from './CookieComplyButton.vue';

export default {
  name: 'CookieComply',
  components: { CookieComplyModal, CookieComplyButton },
  props: {
    headerTitle: { type: String, default: 'Cookie settings' },
    headerDescription: {
      type: String,
      default:
        'We use cookies and similar technologies to help personalize content and offer a better experience. You can opt to customize them by clicking the preferences button.',
    },
    preferencesLabel: { type: String, default: 'Preferences' },
    acceptAllLabel: { type: String, default: 'Accept All' },
    preferences: {
      type: Array,
      default: () => [
        {
          title: 'Privacy Policy',
          description:
            'This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they as essential for the working of basic functionalities of the website.We also use third-party cookies that help us analyze and understand how you use this website, to store user preferences and provide them with content and advertisements that are relevant to you. These cookies will only be stored on your browser with your consent to do so. You also have the option to opt-out of these cookies.But opting out of some of these cookies may have an effect on your browsing experience. Analytics',

          // items: [
          //   { label: 'GoogleAnalytics', value: 'ga' },
          //   { label: 'Essential cookies', value: 'sentry', isEnable: true },
          //   // { label: 'Mapbox', value: 'mapbox' },
          //   // { label: 'New Relic', value: 'newRelic', isEnable: true },
          //   // { label: 'Dog Food', value: 'dogfood' },
          // ],
        },
        {
          title: 'Analytics',
          description:
            "This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.",

          items: [
            { label: 'Google Analytics', value: 'ga' },
            // { label: 'Mapbox', value: 'mapbox' },
            // { label: 'New Relic', value: 'newRelic', isEnable: true },
            // { label: 'Dog Food', value: 'dogfood' },
          ],
        },
        {
          title: 'Necessary',
          description:
            'This cookie is used to store the language preferences of a user to serve up content in that stored language the next time user visit the website.',

          items: [
            { label: 'Necessary', value: '__cfduid', isEnable: true },
            // { label: 'Mapbox', value: 'mapbox' },
            // { label: 'New Relic', value: 'newRelic', isEnable: true },
            // { label: 'Dog Food', value: 'dogfood' },
          ],
        },
      ],
    },
    target: { type: String, default: '#cards' },
  },
  emits: ['on-accept-all-cookies', 'on-save-cookie-preferences'],
  data() {
    return {
      showCookieComply: true,
      isModalOpen: false,
    };
  },
  mounted() {
    if (localStorage.getItem('cookie-comply')) {
      this.showCookieComply = false;
    }
  },
  methods: {
    handleAcceptAll() {
      this.showCookieComply = false;
      localStorage.setItem('cookie-comply', 'all');
      this.$emit('on-accept-all-cookies');
    },
    openPreferences() {
      this.isModalOpen = true;
    },
    onSave(data) {
      this.isModalOpen = false;
      this.showCookieComply = false;

      // transform Proxy into array of selected preferences
      const preferencesArray = Object.values(data);

      localStorage.setItem('cookie-comply', JSON.stringify(preferencesArray));
      this.$emit('on-save-cookie-preferences', preferencesArray);
    },
  },
};
</script>

<style>
@import './styles/variables.css';

.cookie-comply {
  z-index: 1000;
  display: grid;

  width: 98%;
  grid-gap: var(--spacing-lg);
  grid-template-columns: 1fr minmax(35%, 40%);
  position: absolute;
  bottom: var(--spacing-sm);
  left: var(--spacing-sm);
  right: var(--spacing-sm);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
}

@media (max-width: 1024px) {
  .cookie-comply {
    grid-template-columns: none;
  }
}

.cookie-comply__header {
  justify-self: flex-start;
  text-align: initial;
}

.cookie-comply__header-title,
.cookie-comply__header-description {
  margin: 0;
}
.cookie-comply__header-title {
  margin-bottom: var(--spacing-sm);
}
.cookie-comply__header-description {
  line-height: 20px;
}

.cookie-comply__actions {
  display: grid;
  grid-gap: var(--spacing-lg);
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
}

@media (max-width: 480px) {
  .cookie-comply__header {
    margin-bottom: var(--spacing-sm);
  }

  .cookie-comply__actions {
    grid-template-columns: auto;
  }
}

.cookie-comply__button-accept {
  background-color: var(--color-green);
  color: var(--color-white);
  border: none;
}
</style>
