<template>
  <section id="poss">
    <div class="control">
      <div class="top">
        <div class="img-holder i1">
          <img src="../../images/Group262.png" />
        </div>
        <div class="text-holder t1">
          <div class="text-box tx1">
            <h2>Itt a lehetőség, hogy még többet adj diákjaidnak</h2>
            <p>
              A tanulók imádni fogják, ha Te mutatsz nekik elsőként egy olyan
              eszközt, amivel gyorsabb, egyszerűbb és játékosabb lesz a
              nyelvtanulás. Az elégedett, motivált tanulóval könnyebb a munka és
              garantáltan kitart melletted, amíg célját el nem éri.
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="img-holder i2">
          <img src="../../images/groip.png" />
        </div>
        <div class="text-holder t2">
          <div class="text-box tx2">
            <h2>Neked készítjük, veled készítjük</h2>
            <p>
              Csatlakozz elsők között a Tongie tanári közösségébe, hogy együtt
              még jobbá tehessük. Ha most csatlakozol korai tesztelőink közé
              teljes körű hozzáférést és azonnali használatot biztosítunk az
              apphoz. Mindössze annyit kérünk tőled, hogy mondd el, hogy tetszik
              és mi az, amin változtatnál.
            </p>
          </div>
        </div>
      </div>
    </div>
    <span class="bubble b1"> </span><span class="bubble b2"></span>
    <span class="bubble b3"> </span>
  </section>
</template>
<script>
import '../../css/modules/possibility.css';
export default {};
</script>
<style scoped>
section {
  width: 100vw;
  height: 100vh;
  position: relative;
  /* overflow-x: hidden !important; */
}
section .b1 {
  width: 63px;
  height: 101px;
  background: #551188 0% 0% no-repeat padding-box;
  border-radius: 15px;
  top: 12%;
  left: 0;
}
section .b2 {
  background: #551188 0% 0% no-repeat padding-box;
  border-radius: 5px;
  top: 0;
  left: 8%;
  width: 29px;
  height: 29px;
}
section .b3 {
  background: #551188 0% 0% no-repeat padding-box;
  width: 63px;
  height: 101px;
  border-radius: 15px;
  top: 45%;
  right: -2%;
}
.img-holder img {
  max-width: 450px;
  max-height: 450px;
  z-index: -1;
  object-fit: cover;
}
.control {
  width: 90vw;
  display: flex;
  height: 100vh;
  flex-direction: column;
  /* justify-content: center; */
  position: relative;
  align-items: center;
  margin: 0 auto;
}
.top,
.bottom {
  width: 100%;
  height: 50%;
  position: relative;

  /* overflow: hidden; */
}
.i1 {
  width: 27%;
  left: 0;
  position: absolute;
  height: 100%;
  /* top: 0; */
}
.i2 {
  width: 27%;
  right: 0;

  position: absolute;
  height: auto;
  height: 100%;
}
.t1 {
  position: absolute;
  left: 27%;
  width: 73%;
  height: 100%;
}
.t2 {
  position: absolute;
  right: 27%;
  width: 73%;

  height: 100%;
}
.text-box {
  position: absolute;
  top: 50%;
  height: 70%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 50px #1313131a;
  border-radius: 30px;
  width: 100%;
  z-index: 5;
  padding: 0 10% 0% 5%;
}
.text-box h2 {
  color: #551188;
  font-family: 'Poetsen', sans-serif;
  font-size: 36px;
  text-align: left;
}
.text-box p {
  font-family: 'Roboto', sans-serif;
  color: #2f2f2f;
  font-size: 29px;
  text-align: justify;
  line-height: 2rem;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: left;
}
.tx1 {
  right: 5%;
  transform: translate(0, -50%);
}
.tx2 {
  left: 5%;
  transform: translate(0, -50%);
}
</style>
