<template>
  <the-layer></the-layer>
</template>

<script>
import TheLayer from './components/ui/TheLayer.vue';

export default {
  components: {
    TheLayer,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
</style>
