<template>
  <section id="bubbles">
    <div class="bottom">
      <div class="inner-grid">
        <span class="grey i1"></span>
        <img src="../../images/fast-time.png" class="i2" />
        <h3 class="f1">Pillantok alatt használatba veheted</h3>
        <p class="f2">
          Szókártyáidat gyorsan és egyszerűen töltheted fel, vagy akár
          felhasználhatod az előre feltöltött tematikus kártyapaklikat is.
        </p>
      </div>
      <div class="inner-grid">
        <span class="grey res"></span>
        <img :src="res" alt="reserch" />
        <h3>Könnyen ellenőrizheted tudásod</h3>
        <p>
          A Tongie-val rendszeresen ellenőrizheted tudásod, így pontosan látod
          majd mely szavak mennek nehezebben, amiket többet kell gyakorolnod.
        </p>
      </div>
      <div class="inner-grid">
        <span class="grey star"> </span>
        <img :src="clock" alt="award" />
        <h3>Plusz motivációt ad a tanuláshoz</h3>
        <p>
          Az appot olyan funkciókkal látjuk el, amivel szabad perceidet szívesen
          töltöd majd szavak tanulásával és eredményeid javításával.
        </p>
      </div>
    </div>
    <div class="btn-cc">
      <base-button mode="shadow-active" @scroll="scrollD"
        >Kipróbálom</base-button
      >
    </div>
    <span class="bubble b1"> </span><span class="bubble b2"></span>
  </section>
</template>

<script>
import BaseButton from '../base/BaseHeaderButton.vue';
import clock from '../../svg/award.svg';
import res from '../../svg/research.svg';
import '../../css/modules/bubbles.css';
export default {
  components: { BaseButton },
  data() {
    return {
      clock,
      res,
    };
  },
  methods: {
    scrollD() {
      const el = document.getElementById('main-ct');
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
section {
  position: relative;
  height: 100vh;
  width: 100%;
}
section .bottom {
  display: grid;
  /* padding: 0 2%; */
  grid-template-columns: repeat(3, 1fr);
  gap: 7%;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
}
section .bottom .inner-grid {
  width: 100%;
  position: relative;
  height: 80%;
}
section .bottom .inner-grid img {
  z-index: 10;
  width: 120px;
  height: 120px;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}
section .bottom .inner-grid .grey {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fafafa 0% 0% no-repeat padding-box;
  width: 220px;
  height: 220px;
  border-radius: 55px;
}
section .bottom .inner-grid h3 {
  position: absolute;
  top: 130px;
  text-align: center;
  word-wrap: normal;
  font-size: 35px;
  color: #2f2f2f;
  font-family: 'Poetsen', sans-serif;
}
section .bottom .inner-grid p {
  position: absolute;
  top: 250px;
  text-align: justify;
  word-wrap: normal;
  font-size: 27px;
  font-weight: 400;
  color: color;
}
section .b1 {
  left: -2.5%;
  top: 15%;
  background: #12001f 0% 0% no-repeat padding-box;
  transform: matrix(-1, -0.09, 0.09, -1, 0, 0);
  width: 80px;
  height: 80px;
  border-radius: 25px;
}
section .b2 {
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  background: #551188 0% 0% no-repeat padding-box;
  left: 2%;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  top: 10%;
}
section .btn-cc {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
}
</style>
