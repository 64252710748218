<template>
  <section id="cards">
    <div class="top">
      <div class="center">
        <div class="text">
          <h1>Szókártyák, ahogy eddig még nem láttad</h1>
          <h4>
            Mindenki ismeri ezt a szótanulási módszert, sokan használják is, de
            nem elég hatékonyan. Megtartottuk a klasszikus szókártyák előnyeit
            és rengeteg kutatás és felmérés után kiegészítettük olyan
            funkciókkal, amik kitöltik a hiányosságokat.
          </h4>
        </div>

        <div class="holder">
          <div class="inner">
            <span class="text tt"
              >Te tudod hogy taníts, mi eszközt adunk hozzá</span
            >
          </div>
          <div class="tr"></div>
          <span class="text dn"
            >Te tudod hogy taníts, mi eszközt adunk hozzá</span
          >
        </div>
      </div>
      <span class="bubble b1"> </span><span class="bubble b2"></span>
      <span class="bubble b3"> </span>
      <span class="bubble b4"></span>
    </div>
  </section>
</template>

<script>
import clock from '../../svg/award.svg';
import res from '../../svg/research.svg';
import '../../css/modules/cards.css';

export default {
  data() {
    return {
      clock,
      res,
    };
  },
};
</script>

<style scoped>
section {
  height: 100vh;
  position: relative;
}
section .top {
  width: 100vw;
  height: 100%;
  position: relative;
}

section .top .center {
  position: relative;
  width: 80vw;
  margin: auto;
  height: 100%;
}
section .top .bubble {
  position: absolute;
  transform: translate(-50%, -50%);
}
section .top .b1 {
  left: -2.5%;
  top: 15%;
  background: #551188 0% 0% no-repeat padding-box;
  transform: matrix(-1, -0.09, 0.09, -1, 0, 0);
  width: 100px;
  height: 100px;
  border-radius: 30px;
}
section .top .b2 {
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  background: #12001f 0% 0% no-repeat padding-box;
  right: -2%;
  width: 65px;
  height: 65px;
  border-radius: 20px;
  top: 30%;
}
section .top .b3 {
  background: #551188 0% 0% no-repeat padding-box;
  border-radius: 25px;
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  right: 2%;
  width: 100px;
  height: 100px;
  border-radius: 30px;
  top: 55%;
}
section .top .b4 {
  transform: matrix(-1, -0.09, 0.09, -1, 0, 0);
  background: #12001f 0% 0% no-repeat padding-box;
  width: 60px;
  height: 60px;
  left: -2%;
  top: 55%;
  border-radius: 20px;
}
section .top .center .holder {
  width: 650px;
  /* overflow: hidden; */
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section .top .center .text h1 {
  color: #2f2f2f;
  position: absolute;
  top: 3%;
  left: 50%;

  transform: translate(-50%, -50%);
  font-family: 'Poetsen', sans-serif;
  font-size: 2.5rem;
}
section .top .center .holder .inner .tt {
  display: none;
}
section .top .center .text h4 {
  color: #2f2f2f;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 50%;
  width: 70%;
  line-height: 2.5rem;
  font-size: 24px;
  font-family: 'Galvij', sans-serif;
  transform: translate(-50%, -50%);
  font-weight: 300;
}
section .top .center .holder .inner {
  position: absolute;
  transform: translate(-50%, -50%);
  transform: rotate(2deg);
  width: 100%;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 65px;
  height: 180px;
  z-index: 1;
}
section .top .center .holder .tr {
  position: absolute;
  transform: rotate(-2deg);
  width: 100%;
  height: 180px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 65px;
  z-index: -1;
}
section .top .center .holder .text {
  color: #fdc827;
  transform: none;
  margin: auto;
  position: absolute;
  left: 55%;
  top: 85px;
  letter-spacing: 2px;
  font-size: 31px;
  transform: translate(-50%, -50%);
  font-family: 'Poetsen', sans-serif;
  z-index: 5;
}
section .bottom {
  width: 90%;
  margin: 100px auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5%;
  /* height: 100%; */
  position: relative;
}
section .btn-c {
  position: absolute;
  bottom: 0;
  margin: -5px auto;
  left: 50%;
  width: 20%;
  transform: translate(-50%, -50%);
}
</style>
