<template>
  <section id="helper">
    <span class="rt"></span>
    <div class="top">
      <h1>Hányszor kezdtél már neki?</h1>
    </div>
    <div class="holder">
      <div class="first"><img src="../../images/Sadteacher.png" /></div>
      <div class="second">
        <h3>Te is küzdesz azzal a problémával, hogy…</h3>
        <div class="text-holder">
          <div class="text">
            <span class="dots ft"></span>
            <span class="para"
              >a tanulók nem elég motiváltak és számonkérhetőek?</span
            >
          </div>
          <div class="text">
            <span class="dots"></span>
            <span class="para"
              >az új eszközök bonyolultak, a régieket viszont már mindenki
              unja?</span
            >
          </div>
          <div class="text">
            <span class="dots"></span>
            <span class="para">
              nincs egy olyan tanári közösség, ahova szívesen tartoznál?
            </span>
          </div>
        </div>
      </div>
      <span class="rt-2"></span>
      <span class="rt-3"></span>
      <div class="btn-cont">
        <base-btn mode="outline" @scroll="scrollD">
          Jól jönne a segítség?</base-btn
        >
      </div>
    </div>
  </section>
</template>

<script>
import BaseBtn from '../base/BaseHeaderButton.vue';
import '../../css/modules/helper.css';
export default {
  components: {
    BaseBtn,
  },
  methods: {
    scrollD() {
      const el = document.getElementById('main-ct');
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
section {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 100vh;
  width: 100vw;
}
section .rt {
  position: absolute;
  transform: matrix(-1, -0.02, 0.02, -1, 0, 0);
  background: #551188 0% 0% no-repeat padding-box;
  width: 80px;
  height: 80px;
  top: 25%;
  left: -2%;
  border-radius: 20px;
}
section .rt-2 {
  position: absolute;
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  background: #551188 0% 0% no-repeat padding-box;
  width: 80px;
  height: 80px;
  bottom: 26%;
  right: -10%;
  border-radius: 20px;
  overflow-x: hidden !important;
}
section .rt-3 {
  position: absolute;
  transform: matrix(1, -0.09, 0.09, 1, 0, 0);
  background: #12001f 0% 0% no-repeat padding-box;
  width: 60px;
  height: 60px;
  top: 26%;
  right: -10%;
  border-radius: 20px;
}
section .top h1 {
  text-align: center;
  font-family: 'Poetsen', sans-serif;
  font-size: 45px;
  color: #2f2f2f;
  letter-spacing: 2px;
}
section .holder {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 0;
  padding-top: 2%;
  width: 80%;
  margin: auto;
  bottom: 20%;
}
section .holder .first {
  width: 100%;
  height: 100%;
}
section .holder .first img {
  max-width: 550px;
  max-height: 550px;
  object-fit: cover;
  border-radius: 8px;
}
section .second {
  position: relative;
  width: 100%;
}
section .second h3 {
  color: #551188;
  font-family: 'Poetsen', sans-serif;
  position: absolute;
  left: -5%;
  font-size: 30px;
  letter-spacing: 1px;
}
section .second .text-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  left: -12%;
  top: 15%;
}
section .second .text-holder .text {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 50px #1313131a;
  border-radius: 30px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  width: auto;
}

section .second .text-holder .text .dots {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 20px;
  width: 20px;
  margin: 10px;
}
section .second .text-holder .text .ft {
  background: #551188 0% 0% no-repeat padding-box;
}
section .second .text-holder .text .para {
  color: #2f2f2f;
  padding: 10px;
  font-size: 20px;
  font-weight: 550;
  letter-spacing: 1px;
}
section .btn-cont {
  width: 20%;
  bottom: 22%;
  position: absolute;
  left: 55%;
  transform: translate(-50%, -50%);
}
</style>
