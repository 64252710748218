<template>
  <section id="learn">
    <div class="top">
      <h2>Mitől lesz a Tongie-val élmény a szótanulás?</h2>
    </div>
    <div class="center">
      <div class="img-holder c1">
        <div class="img-top">
          <div class="imm">
            <img :src="book" alt="book" />
          </div>
          <div class="tex">
            <h4>Tanulást megkönnyítő design</h4>
          </div>
          <div class="para">
            <p>
              Sokat dolgoztunk azon, hogy a Tongie egy letisztult, könnyen
              használható mégis szerethető megjelenést kapjon, amit minden nap
              örömmel használsz majd.
            </p>
          </div>
        </div>
      </div>
      <div class="img-holder c2">
        <div class="img-top">
          <div class="imm">
            <img :src="int" alt="artificial inteligence" />
          </div>
          <div class="tex">
            <h4>Könnyebb fejlődés mesterséges intelligenciával</h4>
          </div>
          <div class="para">
            <p>
              A motorháztető alatt olyan algoritmus dolgozik, ami a szavakat
              nehézség szerint és a diák visszajelzései alapján rangosorolja és
              a lehető leghatékonyabb gyakorisággal mutatja.
            </p>
          </div>
        </div>
      </div>
      <div class="img-holder c3">
        <div class="img-top">
          <div class="imm">
            <img :src="design" alt="organize" />
          </div>
          <div class="tex">
            <h4>Benne van a tanulás pszichológiája</h4>
          </div>
          <div class="para">
            <p>
              A design és a működés minden apró eleme tanulmányok és szakemberek
              tudásával alátámasztva készült. Ezek olyan részletek, melyek
              szinte észrevétlenül segítenek, hogy gyorsabban tanulhass.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn-btn-hol">
        <base-button mode="shadow-active" @scroll="scrollD">
          Kipróbálom</base-button
        >
      </div>
    </div>
    <span class="bubble b1"> </span><span class="bubble b2"></span>
  </section>
</template>
<script>
import BaseButton from '../base/BaseHeaderButton.vue';
import '../../css/modules/learning.css';
import '../../css/extension/lerning.extension.css';
import int from '../../images/int.svg';
import design from '../../images/design.svg';
import book from '../../images/book.svg';
export default {
  components: { BaseButton },
  data() {
    return {
      int,
      book,
      design,
    };
  },
  methods: {
    scrollD() {
      const el = document.getElementById('main-ct');
      el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
<style scoped>
section {
  width: 100vw;
  height: 100vh;
  position: relative;
}
section .top {
  width: 100%;
  height: 10%;
  position: relative;
  overflow-x: hidden !important;
  overflow: hidden;
}
section .top h2 {
  font-size: 40px;

  font-family: 'Poetsen', sans-serif;
  color: #2f2f2f;
  top: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
}
section .center {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

section .center .img-holder {
  top: 5%;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
section .center .img-holder .img-top {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 40% 20% 40%;
  row-gap: 0;
  width: 100%;
  height: 100%;
}
section .center .img-holder .tex,
section .center .img-holder .para {
  text-align: justify;
}
section .center .c1 {
  z-index: -1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 50px #1313131a;
  border-radius: 30px;
  transform: rotateY(2deg);
  transform: translateX(4%) rotate(0.5deg) translateY(-2%);
}
section .center .c2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 99px #13131333;
  border-radius: 30px;
  z-index: 1;
  transform: translateY(-8%);
}
section .center .c3 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 50px #1313131a;
  border-radius: 30px;
  z-index: -1;
  transform: translateX(-4%) rotate(-0.5deg) translateY(-2%);
}
section .bottom {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
}
section .bottom .btn-btn-hol {
  width: 30%;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
