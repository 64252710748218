<template>
  <button :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['mode', 'link'],
  methods: {
    pushRoute() {
      this.$router.push(this.link);
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
  font-family: 'Poetsen', sans-serif;
  height: 60px;
  cursor: pointer;
  font-size: 22px;
}
.active {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #f8f254 0%, #fec524 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 1px 2px 10px #b7762880;
  border-radius: 30px;
  opacity: 1;
}
.passive {
  border: 4px solid #fec524;
  border-radius: 30px;
  opacity: 1;
  color: #fec524;
  background: transparent
    radial-gradient(closest-side at 50% 50%, #551188 0%, #2b0944 100%) 0% 0%
    no-repeat padding-box;
}
.outline {
  color: #551188;
  border-radius: 30px;
  border: 3px solid #551188;
  background: #fff;
}
.shadow-active {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #f8f254 0%, #fec524 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 1px 2px 10px #b7762880;
  border-radius: 30px;
  border: none;
}
</style>
